import React, { useEffect, useMemo, useState  } from "react";
import { Box, Container } from "@mui/material";
import { Button, Tab, Tabs } from "react-bootstrap";

import moment from "moment";
import axios from "axios";
import { getMethod } from "../../../API_SERVICES/ApiServices";
import { Link, useNavigate , useLocation } from "react-router-dom";
import PersonalInformation from "./PersonalInformation";
import YourAddress from "./YourAddress";
import NomineeDetails from "./NomineeDetails";
import BankInvestmentDetails from "./BankInvestmentDetails";
import ReviewAndPay from "./ReviewAndPay";
import Payment from "./Payment";
import Report from "./Report";
import InvestmentDetails from "./InvestmentDetails";


const AddInvestor = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [key, setKey] = useState("personalInformation");

 
  let location = useLocation();
  location = location.pathname.split("/");
  location = location[1];
  //console.log("Location", location);

  const withoutLogin = [
    {
      id: 1,
      eventKey: "personalInformation",
      title: "Personal Information",
      component: <PersonalInformation onNext={() => setKey("yourAddress")} />,
    },
    {
      id: 2,
      eventKey: "yourAddress",
      title: "Your Address",
      component: (
        <YourAddress
          onNext={() => setKey("nomineeDetails")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },
    {
      id: 3,
      eventKey: "nomineeDetails",
      title: "Nominee Details",
      component: (
        <NomineeDetails
          onNext={() => setKey("bankInvestmentDetails")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },
    {
      id: 4,
      eventKey: "bankInvestmentDetails",
      title: "Bank / Investment Details",
      component: (
        <BankInvestmentDetails
          onNext={() => setKey("reviewPay")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },

    {
      id: 5,
      eventKey: "reviewPay",
      title: "Review & Submit",
      component: (
        <ReviewAndPay
          onNext={() => setKey("paymentDetails")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },
    // {
    //   id: 6,
    //   eventKey: "paymentDetails",
    //   title: "Payment Details",
    //   component: <Payment />,
    // },
    // {
    //   id: 6,
    //   eventKey: "Report",
    //   title: "Report",
    //   component: <Report />,
    // },
  ];

  const withLogin = [
    {
      id: 1,
      eventKey: "personalInformation",
      title: "Investment Details",
      component: <InvestmentDetails onNext={() => setKey("nomineeDetails")} />,
    },
    {
      id: 2,
      eventKey: "nomineeDetails",
      title: "Nominee Details",
      component: (
        <NomineeDetails onNext={() => setKey("bankInvestmentDetails")} />
      ),
    },
    {
      id: 3,
      eventKey: "bankInvestmentDetails",
      title: "Bank Details",
      component: <BankInvestmentDetails onNext={() => setKey("reviewPay")} />,
    },
    {
      id: 5,
      eventKey: "reviewPay",
      title: "Review & Submit",
      component: <ReviewAndPay onNext={() => setKey("paymentDetails")} />,
    },
    {
      id: 6,
      eventKey: "paymentDetails",
      title: "Payment Details",
      component: <Payment />,
    },
  ];

  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log("user", user);
  let tabNames =
    location === "add-investment" && user?.id ? withoutLogin : withoutLogin;

    const navigate = useNavigate();

    const Navigation =()=>{
        
      sessionStorage.removeItem("investmentDetails");
      sessionStorage.removeItem("bank");
      sessionStorage.removeItem("nominee");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("tranche");
      sessionStorage.removeItem("address");
      navigate("/investment/add-existing-investor")
      
  }

  return (
    <>
      {apiLoading ? (
        <>
          <Container className="text-center">
            <div className="loader">
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container>
          <div
              style={{
                marginTop: "10px",
                marginLeft: "-50px",
                marginBottom: "20px",
                
                marginRight: "5px",
                display:'flex',
                flexDirection:"row",
                justifyContent:"space-between"
                // width: "307px",
              }}
            >
              <h3 className="bg-primary fs-4  fw-bold  p-2 px-5  rounded-0" style={{ backgroundColor: "#fc9200"}}>
              New Investment
              </h3>
              <h3 onClick={Navigation}   className="bg-primary fs-4  fw-bold  p-2 px-5  rounded-0"  style={{ backgroundColor: "#fc9200", textDecoration:"none", color:"#000" ,cursor:"pointer"}}>
               Existing User Investment
              </h3>
            </div>
            <Container className="investmentsContainer">
            <div className="investTab">
        {location === "new-investment" && (
          <span className="bgorange fw-bold fs-3 py-2 px-3">
            NEW INVESTMENT
          </span>
        )}
        <div className="a mt-4 mb-2 p-0">
          <Tabs
             
            activeKey={key} 
            id="justify-tab-example"
            className="mb-3  "
            justify
            onSelect={(k) => setKey(k)}
          >
            {tabNames.map((tab, index) => (
              <Tab
                key={index}
                tabClassName="rounded-0 primary "
                eventKey={tab.eventKey}
                title={tab.title}
              >
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
            
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default AddInvestor;
