import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "../../../App.scss";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

const FormComponent = ({
  formLabel,
  type,
  placeholder,
  file,
  email,
  date,
  tel,
  options,
  required,
  name,
  onHandleChange,
  formRef,
  regex,
  isUpperCase,
  info,
  titleInfo,
  validations,
  details,
  onBlur,
  value,
  selected,
  readonly,
  disabled,
  copy,
  paste,
  maxValue,
  hrefLink,
  handleShow,
  defaultValue,
  minValue,
  dummy,
  visibleShow,
  setVisibleShow,
  onKeyDown,
  aadharHandleChange,
  maxLength,
}) => {
  const [visibele, setVisible] = useState();

  return (
    <div className="d-flex flex-column">
      <Form.Label className="form-label">
        {formLabel} {required && <span className="text-danger">*</span>}{" "}
      </Form.Label>

      {type === "select" ? (
        <select
          required={required === true ? true : false}
          disabled={disabled === true ? true : false}
          ref={formRef}
          onChange={onHandleChange}
          name={name}
          className="form-select rounded-0 form-label"
        >
          <option value="">Select Option</option>
          {options?.map((opt, ind) => (
            <option selected={selected == opt.id} key={ind} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      ) : type === "view" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={hrefLink}
            alt={formLabel}
            className="img-thumbnail"
            style={{
              width: formLabel === "Profile Photo" && "70%",
              height: "200px",
            }}
          />
        </div>
      ) : //width={350}
      type === "link" ? (
        <a target="_blank" href={hrefLink}>
          {formLabel}
        </a>
      ) : type === "password" ? (
        <div style={{ position: "relative" }}>
          <input
            name={name}
            onChange={onHandleChange}
            onBlur={onBlur}
            // value={value}
            defaultValue={defaultValue}
            readOnly={readonly === true ? true : false}
            max={maxValue}
            min={minValue}
            disabled={disabled === true ? true : false}
            onPaste={paste === true ? (e) => e.preventDefault() : ""}
            onCopy={copy === true ? (e) => e.preventDefault() : ""}
            required={required === true ? true : false}
            type={!visibele ? type : "number"}
            className="form-control rounded-0 form-label"
          />
          <button
            type="button"
            className="password_button"
            onClick={() => {
              setVisible(!visibele);
              setVisibleShow(!visibleShow);
            }}
          >
            {!visibele ? (
              <>
                <AiOutlineEye style={{ width: "16px", height: "16px" }} />
              </>
            ) : (
              <>
                <AiOutlineEyeInvisible
                  style={{ width: "16px", height: "16px" }}
                />
              </>
            )}
          </button>
        </div>
      ) : type === "textarea" ? (
        <Form.Control
          title={titleInfo}
          style={{ textTransform: isUpperCase === true ? "uppercase" : "" }}
          required={required === true ? true : false}
          pattern={regex}
          ref={formRef}
          name={name}
          className="form-control rounded-0 form-label"
          type={type}
          onChange={onHandleChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          readOnly={readonly === true ? true : false}
          max={maxValue}
          min={minValue}
          disabled={disabled === true ? true : false}
          onPaste={paste === true ? (e) => e.preventDefault() : ""}
          onCopy={copy === true ? (e) => e.preventDefault() : ""}
          as="textarea"
        />
      ) : type === "aadharCard" ? (
        <input
          title={titleInfo}
          style={{ textTransform: isUpperCase === true ? "uppercase" : "" }}
          required={required === true ? true : false}
          pattern={regex}
          ref={formRef}
          name={name}
          className="form-control rounded-0 form-label"
          type="text"
          autoComplete="off"
          onChange={aadharHandleChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          readOnly={readonly === true ? true : false}
          max={maxValue}
          maxLength={maxLength}
          min={minValue}
          disabled={disabled === true ? true : false}
          onPaste={paste === true ? (e) => e.preventDefault() : ""}
          onCopy={copy === true ? (e) => e.preventDefault() : ""}
        />
      ) 
      // : type === "date" ? (
      //   <input
      //     title={titleInfo}
      //     style={{ textTransform: isUpperCase === true ? "uppercase" : "" }}
      //     required={required === true ? true : false}
      //     pattern={regex}
      //     ref={formRef}
      //     name={name}
      //     className="form-control rounded-0 form-label"
      //     type="date"
      //     format="dd-mmm-yyyy"
      //     autoComplete="off"
      //     onBlur={onBlur}
      //     value={value}
      //     defaultValue={defaultValue}
      //     readOnly={readonly === true ? true : false}
      //     disabled={disabled === true ? true : false}
      //   />
      // ) 
      : (
        <input
          title={titleInfo}
          style={{ textTransform: isUpperCase === true ? "uppercase" : "" }}
          required={required === true ? true : false}
          pattern={regex}
          ref={formRef}
          name={name}
          className="form-control rounded-0 form-label"
          type={type}
          autoComplete="off"
          onChange={onHandleChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          readOnly={readonly === true ? true : false}
          max={maxValue}
          maxLength={maxLength}
          min={minValue}
          disabled={disabled === true ? true : false}
          onPaste={paste === true ? (e) => e.preventDefault() : ""}
          onCopy={copy === true ? (e) => e.preventDefault() : ""}
        />
      )}
      {!validations && <small style={{ fontSize: "11px" }}>{info}</small>}
      <small className="text-danger" style={{ fontSize: "11px" }}>
        {validations}
      </small>
    </div>
  );
};

export default FormComponent;
