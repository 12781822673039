import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { getMethod, postMethod } from "../../../API_SERVICES/ApiServices";
import FormComponent from "../AddInvestor/FormComponent";
import { onNextPage, onNomineeDetails } from "../AddInvestor/InvestRegisterSlice";

const ENomineeDetails = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch();
  const investedDetails = useSelector(
    (state) => state?.storedSates?.investmentDetails
  );
  const userDetails = useSelector(
    (state) => state?.storedSates?.personalDetails
  );

  const activeKeyData = useSelector((state) => state?.storedSates?.activeTab);
  const [nomineeScroll, setNomineeScroll] = useState(
    activeKeyData === "nomineeDetails" ? true : false
  );

  console.log("activeKeyData", activeKeyData);
  console.log("investedDetails", investedDetails);
  const [relationshipData, setRelationshipData] = useState({
    relation_name: null,
    relation_dob: null,
    relationship: null,
    address: null,
  });
  const [errors, setErrors] = useState({
    relation_name: null,
    relation_dob: null,
    relationship: null,
    address: null,
  });

  const [relationType, setRelationType] = useState([]);
  const [existingNominiees, setExistingNominees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExistingNominee, setIsExistingNominee] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    // if (relationshipData?.relation_dob !== null) {
      getMethod("api/relationship", controller.signal).then((res) => {
        let data = [];
        res?.data?.data?.forEach((ele) => {
          ele.name = ele.relationship_name;
          data.push(ele);
        });

        setRelationType(data);
      });
    // }

    return () => {
      controller.abort();
    };
  }, [relationshipData?.relation_dob]);

  useEffect(() => {
    console.log("nomineeScroll", nomineeScroll);
    if (nomineeScroll === true) {
      window.scrollTo(0, 0);
      document.getElementById("root").scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
      document.getElementById("root").scrollTo(0, 0);
    }
  }, [nomineeScroll, activeKeyData]);

  const formDetails = [
    {
      id: 1,
      formLabel: "Name (as it appears on Nominee PAN Card)",
      type: "text",
      placeHolder: "Name",
      name: "relation_name",
      required: true,
      isUpperCase: true,
      titleInfo: "Enter name as per PAN Card",
      error: errors.relation_name,
      value: relationshipData?.relation_name,
    },
    {
      id: 2,
      formLabel: "DOB (DD/MM/YYYY)",
      type: "date",
      placeHolder: "DD/MM/YYYY",
      name: "relation_dob",
      required: true,
      titleInfo: "Enter Date of Birth",
      error: errors.relation_dob,
      value: relationshipData?.relation_dob,
    },
    {
      id: 3,
      formLabel: "Relationship",
      type: "select",
      placeHolder: "Select Relationship",
      required: true,
      name: "relationship",
      option: relationType,
      titleInfo: "Select Relationship",
      error: errors.relationship,
      value: relationshipData?.relationship,
    },
    {
      id: 3,
      formLabel: "Nominee Address",
      type: "textarea",
      placeHolder: "Enter Address",
      required: true,
      name: "address",
      titleInfo: "Enter Address",
      error: errors.address,
      value: relationshipData?.address,
    },
  ];

  const nextPage = () => {
    dispatch(onNextPage("bankInvestmentDetails"));
  };
  const previousPage = () => {
    if (window.location.pathname === "/new-investment") {
      dispatch(onNextPage("personalInformation"));
    } else {
      dispatch(onNextPage("yourAddress"));
      onPrevious("yourAddress");
    }
  };
  const formRef = useRef([]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setRelationshipData({ ...relationshipData, [name]: value });
  };
  const localNominee = JSON.parse(sessionStorage.getItem("nominee"));

  const onSubmit = (e) => {
    e.preventDefault();
    if (isExistingNominee) {
      nextPage();
      onNext();
    } else {
      const localuser = JSON.parse(sessionStorage.getItem("user"));

      let data = { ...relationshipData };
      data.relationship = parseInt(relationshipData.relationship);

      console.log("nomi", data);
      const formData = new FormData();
      formData.append("user_id", parseInt(localuser?.id));
      formData.append("nominee_name", data?.relation_name?.toUpperCase());
      formData.append("address", data?.address?.toUpperCase());
      formData.append("relationship_id", parseInt(data?.relationship));
      formData.append("dob", data?.relation_dob);
      formData.append("status", parseInt(1));

      if (localNominee && window.location.pathname !== "/new-investment")
        formData.append("_method", "PUT");

      if (window.location.pathname === "/new-investment") {
        setTimeout(() => {
          setLoading(true);
          postMethod("api/user-nominee", formData)
            .then((res) => {
              console.log(res);
              if (res?.data?.status === true) {
                nextPage();
                onNext();
                setLoading(false);
                dispatch(onNomineeDetails(res?.data?.data));
                sessionStorage.setItem(
                  "nominee",
                  JSON.stringify(res?.data?.data)
                );
              }
            })
            .catch((err) => {
              setLoading(false);
              setErrors({
                ...errors,
                pan_card_number: err?.response?.data?.errors?.pan_card_number,
                mobile_number: err?.response?.data?.errors?.mobile_number,
                aadhaar_card_number:
                  err?.response?.data?.errors?.aadhaar_card_number,
              });
              console.log(err?.response?.data?.errors);
            });
        }, 100);
      } else {
        if (localNominee) {
          setTimeout(() => {
            setLoading(true);
            postMethod("api/user-nominee/" + localNominee?.id, formData)
              .then((res) => {
                console.log(res);
                if (res?.data?.status === true) {
                  nextPage();
                  onNext();
                  setLoading(false);
                  dispatch(onNomineeDetails(res?.data?.data));
                  sessionStorage.setItem(
                    "nominee",
                    JSON.stringify(res?.data?.data)
                  );
                }
              })
              .catch((err) => {
                setLoading(false);
                setErrors({
                  ...errors,
                  pan_card_number: err?.response?.data?.errors?.pan_card_number,
                  mobile_number: err?.response?.data?.errors?.mobile_number,
                  aadhaar_card_number:
                    err?.response?.data?.errors?.aadhaar_card_number,
                });
                console.log(err?.response?.data?.errors);
              });
          }, 100);
        } else {
          setTimeout(() => {
            setLoading(true);
            postMethod("api/user-nominee", formData)
              .then((res) => {
                console.log(res);
                if (res?.data?.status === true) {
                  nextPage();
                  onNext();
                  setLoading(false);
                  dispatch(onNomineeDetails(res?.data?.data));
                  sessionStorage.setItem(
                    "nominee",
                    JSON.stringify(res?.data?.data)
                  );
                }
              })
              .catch((err) => {
                setLoading(false);
                setErrors({
                  ...errors,
                  pan_card_number: err?.response?.data?.errors?.pan_card_number,
                  mobile_number: err?.response?.data?.errors?.mobile_number,
                  aadhaar_card_number:
                    err?.response?.data?.errors?.aadhaar_card_number,
                });
                console.log(err?.response?.data?.errors);
              });
          }, 100);
        }
      }
    }
  };
  // const onSubmitUpdate = (e) => {
  //   e.preventDefault();
  //   console.log("local", localNominee);
  //   const localuser = JSON.parse(sessionStorage.getItem("user"));
  //   let data = { ...relationshipData };
  //   data.relationship = parseInt(relationshipData.relationship);

  //   console.log("nomi", data);
  //   const formData = new FormData();
  //   formData.append(
  //     "user_id",
  //     parseInt(userDetails?.id) ?? parseInt(localuser?.id)
  //   );
  //   formData.append("nominee_name", (data?.relation_name)?.toUpperCase());
  //   formData.append("address", data?.address);
  //   formData.append("relationship_id", parseInt(data?.relationship));
  //   formData.append("dob", data?.relation_dob);
  //   formData.append("status", parseInt(1));
  //   formData.append("_method", "PUT");

  //   setTimeout(() => {
  //     setLoading(true);
  //     postMethod("api/user-nominee/" + localNominee?.id, formData)
  //       .then((res) => {
  //         console.log(res);
  //         if (res?.data?.status === true) {
  //           nextPage();
  //           setLoading(false);
  //           dispatch(onNomineeDetails(res?.data?.data));
  //           sessionStorage.setItem("nominee", JSON.stringify(res?.data?.data));
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         setErrors({
  //           ...errors,
  //           pan_card_number: err?.response?.data?.errors?.pan_card_number,
  //           mobile_number: err?.response?.data?.errors?.mobile_number,
  //           aadhaar_card_number: err?.response?.data?.errors?.aadhaar_card_number,
  //         });
  //         console.log(err?.response?.data?.errors);
  //       });
  //   }, 100);
  // };
  const divRef = useRef();
  useEffect(() => {
    const controller = new AbortController();
    const localuser = JSON.parse(sessionStorage.getItem("user"));

    if (window.location.pathname === "/new-investment") {
      getMethod("api/user-nominee/" + localuser?.id, controller.signal).then(
        (res) => {
          setExistingNominees(res?.data?.data);
        }
      );
    }
  }, []);

  const existingHandle = (e) => {
    let newData = existingNominiees.find((data) => {
      if (data.id == e.target.value) return data;
    });

    const value = { ...newData };
    console.log("value", value);

    sessionStorage.setItem("nominee", JSON.stringify(newData));
    setRelationshipData({
      ...relationshipData,
      relation_name: value.nominee_name,
      relation_dob: value.dob,
      relationship: value.relationship_id,
      address: value.address,
    });
  };

  console.log("values", relationshipData);

  return (
    <Fade>
      <Container ref={divRef} id="topside">
        <form onSubmit={onSubmit}>
          {window?.location?.pathname === "/new-investment" && (
            <div className="d-flex my-3">
              <Form.Group className="col-6">
                <Form.Check
                  label="Continue With Existing Nominee"
                  inline
                  id="isExisting"
                  type="checkbox"
                  onClick={() => setIsExistingNominee(!isExistingNominee)}
                />
              </Form.Group>
              {isExistingNominee && (
                <Form.Select
                  name="nominee_select"
                  onChange={existingHandle}
                  className="form-select rounded-0"
                >
                  <option value="">Select Nominee</option>
                  {existingNominiees?.map((data, index) => (
                    <option value={data.id}>{data?.nominee_name}</option>
                  ))}
                </Form.Select>
              )}
            </div>
          )}
          <Row>
            {formDetails.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                <FormComponent
                  titleInfo={detail?.titleInfo}
                  key={index}
                  name={detail?.name}
                  info={detail?.info}
                  formRef={(el) => (formRef.current[index] = el)}
                  formLabel={detail?.formLabel}
                  type={detail?.type}
                  placeholder={detail?.placeHolder}
                  options={detail?.option}
                  required={!isExistingNominee ?? detail?.required}
                  regex={detail?.regex}
                  isUpperCase={detail?.isUpperCase}
                  onHandleChange={onHandleChange}
                  validations={detail?.error}
                  disabled={isExistingNominee}
                  value={detail?.value}
                  selected={detail?.value}
                />
              </Col>
            ))}
          </Row>

          <div className="buttonGroup mb-3">
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="button"
              onClick={previousPage}
              variant="outline-warning rounded-0"
            >
              <span
                style={{ fontSize: "28px" }}
                className="mdi mdi-chevron-left"
              ></span>
              Previous
            </Button>
            <Button
              type="submit"
              className="d-flex justify-content-center align-items-center commonBtn"
              variant="warning rounded-0"
            >
              Next
              {loading ? (
                <Spinner
                  style={{
                    fontSize: "28px",
                    fontWeight: "100",
                    marginLeft: "5px",
                  }}
                  size="sm"
                  animation="border"
                  variant="light"
                />
              ) : (
                <span
                  style={{ fontSize: "28px", marginLeft: "5px" }}
                  className="mdi mdi-chevron-right"
                ></span>
              )}
            </Button>
          </div>
        </form>
      </Container>
      {/* <Button
        onClick={() => {
          setNomineeScroll(true);
          console.log("tapped");
        }}
      >
        <a href="#root">hi</a>
      </Button> */}
    </Fade>
  );
};

export default ENomineeDetails;
