import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormComponent from "../AddInvestor/FormComponent";
import { onBankDetails, onNextPage } from "../AddInvestor/InvestRegisterSlice";
import {
  getData,
  getDetailsOfBank,
  getMethod,
  postMethod,
  updateMethod,
} from "../../../API_SERVICES/ApiServices";
import { async } from "q";
import { Fade, Slide } from "react-reveal";
import moment from "moment/moment";
import { AiOutlineEye } from "react-icons/ai";
const EBankInvestmentDetails = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch();


  const priceFormat = (price) => parseInt(price).toLocaleString("en-IN");
  const [cancelledCheck, setCancelledCheck] = useState(false);
  const [errors, setErrors] = useState({});
  const [trancheDetails, setTrancheDetails] = useState();
  const [tranche, setTranche] = useState([]);
  const [agentsDetails, setAgentDetails] = useState([]);
  const activeKeyData = useSelector((state) => state?.storedSates?.activeTab);
  const [loading, setLoading] = useState(false);
  const [investmentPeriodData, setInvestmentPeriodData] = useState([]);
  const [interestPeriod, setInterestPeriod] = useState([]);
  
  const [isExistingBankAccount, setIsExistingBankAccount] = useState(false);
  const [existingBankAccount, setExistingBankAccount] = useState([]);

  
  const localInvestedData = JSON.parse(
    sessionStorage.getItem("investmentDetails")
  );
  console.log(localInvestedData?.resident,"myress");
  const [personalData, setPersonalData] = useState({
    account_number: null,
    confirm_account_number: null,
    account_holder_name: null,
    ifsc_code: null,
    bank_name: null,
    bank_branch: null,
    bank_city: null,
    upload_cancelled_cheque: null,
    agent_code: null,
    select_tranche: null,
    enter_deposit_amount: localInvestedData?.deposit_amount,
    is_resident: localInvestedData?.resident,
    interest_payment_id:1,
    interest_payment: localInvestedData?.interest_payment,
    investment_period_id: 3,
    profit_amount: localInvestedData?.profit_amount,
  });

  const userDetails = useSelector(
    (state) => state?.storedSates?.personalDetails
  );
  const investmentDetails = useSelector(
    (state) => state?.storedSates?.investmentDetails
  );
  const addressDetails = useSelector(
    (state) => state?.storedSates?.addressDetails
  );
  const storedBankDetails = useSelector(
    (state) => state?.storedSates?.bankDetails
  );
  const nomineeDetails = useSelector(
    (state) => state?.storedSates?.nomineeDetails[0]
  );
  // -- lakshmanan
  const [visibleShow, setVisibleShow] = useState(false);
  // -- lakshmanan

  // function price_in_words(price) {
  //   var sglDigit = [
  //       "Zero",
  //       "One",
  //       "Two",
  //       "Three",
  //       "Four",
  //       "Five",
  //       "Six",
  //       "Seven",
  //       "Eight",
  //       "Nine",
  //     ],
  //     dblDigit = [
  //       "Ten",
  //       "Eleven",
  //       "Twelve",
  //       "Thirteen",
  //       "Fourteen",
  //       "Fifteen",
  //       "Sixteen",
  //       "Seventeen",
  //       "Eighteen",
  //       "Nineteen",
  //     ],
  //     tensPlace = [
  //       "",
  //       "Ten",
  //       "Twenty",
  //       "Thirty",
  //       "Forty",
  //       "Fifty",
  //       "Sixty",
  //       "Seventy",
  //       "Eighty",
  //       "Ninety",
  //     ],
  //     handle_tens = function (dgt, prevDgt) {
  //       return 0 == dgt
  //         ? ""
  //         : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
  //     },
  //     handle_utlc = function (dgt, nxtDgt, denom) {
  //       return (
  //         (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
  //         (0 != nxtDgt || dgt > 0 ? " " + denom : "")
  //       );
  //     };

  //   var str = "",
  //     digitIdx = 0,
  //     digit = 0,
  //     nxtDigit = 0,
  //     words = [];
  //   if (((price += ""), isNaN(parseInt(price)))) str = "";
  //   else if (parseInt(price) > 0 && price.length <= 10) {
  //     for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
  //       switch (
  //         ((digit = price[digitIdx] - 0),
  //         (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
  //         price.length - digitIdx - 1)
  //       ) {
  //         case 0:
  //           words.push(handle_utlc(digit, nxtDigit, ""));
  //           break;
  //         case 1:
  //           words.push(handle_tens(digit, price[digitIdx + 1]));
  //           break;
  //         case 2:
  //           words.push(
  //             0 != digit
  //               ? " " +
  //                   sglDigit[digit] +
  //                   " Hundred" +
  //                   (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
  //                     ? " and"
  //                     : "")
  //               : ""
  //           );
  //           break;
  //         case 3:
  //           words.push(handle_utlc(digit, nxtDigit, "Thousand"));
  //           break;
  //         case 4:
  //           words.push(handle_tens(digit, price[digitIdx + 1]));
  //           break;
  //         case 5:
  //           words.push(handle_utlc(digit, nxtDigit, "Lakh"));
  //           break;
  //         case 6:
  //           words.push(handle_tens(digit, price[digitIdx + 1]));
  //           break;
  //         case 7:
  //           words.push(handle_utlc(digit, nxtDigit, "Crore"));
  //           break;
  //         case 8:
  //           words.push(handle_tens(digit, price[digitIdx + 1]));
  //           break;
  //         case 9:
  //           words.push(
  //             0 != digit
  //               ? " " +
  //                   sglDigit[digit] +
  //                   " Hundred" +
  //                   (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
  //                     ? " and"
  //                     : " Crore")
  //               : ""
  //           );
  //       }
  //     str = words.reverse().join("");
  //   } else str = "";
  //   return str;
  // }

  // console.log(price_in_words(1250000000));
  // console.log(price_in_words(9999999999));

  const storedStates = useSelector((state) => state?.storedSates);
  console.log("storedStates", storedStates);

  useEffect(() => {
    const controller = new AbortController();
    if (personalData.ifsc_code !== null) {
      getMethod("api/agent", controller.signal).then((res) => {
        let data = [];
        data = res?.data?.data?.forEach((ele) => {
          ele.name = ele.agent_name;
          data.push(ele);
          setAgentDetails(data);
        });
      });
    }

    return () => {
      controller.abort();
    };
  }, [personalData.ifsc_code]);

  useEffect(() => {
    const controller = new AbortController();

    if (personalData.bank_city !== null) {
      getMethod("api/project-tranche", controller.signal).then((res) => {
        let data = [];

        data = res?.data?.data
          ?.filter((k) => k.status === 1)
          ?.forEach((ele) => {
            console.log(ele);
            ele.name = ele.tranche_name;
            data.push(ele);
            setTranche(data);
          });
      });
    }

    return () => {
      controller.abort();
    };
  }, [personalData.bank_city]);
  useEffect(() => {
    const controller = new AbortController();

    getData("api/investment-period")
      .then((res) => res.json())
      .then((result) => {
        const data = result.data.filter((result) => result.status === 1);
        console.log(data,"myuyuuy");
        setInvestmentPeriodData(data);
      });

    getData("api/interest-profit-payment")
      .then((res) => res.json())
      .then((result) => {
        console.log(result.data ,"investper")
        // setInterestPeriod(result.data);
        setInterestPeriod(result.data.filter((res)=>res.interest_profit_payment==="Monthly"))
        // .filter((result) => result.status === 1)
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    console.log(tranche);
  }, [tranche]);
  useEffect(() => {
    console.log(personalData?.upload_cancelled_cheque);

    if (personalData?.upload_cancelled_cheque != undefined) {
      setCancelledCheck(true);
    }
  }, [personalData?.upload_cancelled_cheque]);
  const bankDetails = [
    {
      id: 1,
      formLabel: "Account Number",
      type: `password`,
      placeHolder: "Account Number",
      required: true,
      name: "account_number",
      value: personalData?.account_number,
      isCopy: true,
      isPaste: true,
      visibleShow: visibleShow,
      setVisibleShow: setVisibleShow,
    },
    {
      id: 2,
      formLabel: "Confirm Account Number",
      type: `password`,
      placeHolder: "Confirm Account Number",
      required: true,
      name: "confirm_account_number",
      value: personalData?.confirm_account_number,
      error: errors.confirm_account_number,
      isCopy: true,
      isPaste: true,
      visibleShow: visibleShow,
      setVisibleShow: setVisibleShow,
    },

    {
      id: 3,
      formLabel: "Account Holder Name",
      type: "text",
      placeHolder: "Account Holder Name",
      required: true,
      isUpperCase: true,
      name: "account_holder_name",
      titleInfo: "Enter name as per bank",
      value: personalData?.account_holder_name,
    },
    {
      id: 4,
      formLabel: "IFSC Code",
      type: "text",
      placeHolder: "IFSC Code.",
      required: true,
      name: "ifsc_code",
      value: personalData?.ifsc_code,
      isUpperCase: true,
      error: errors.ifsc_code,
      isCopy: true,
      isPaste: true,
    },
    {
      id: 5,
      formLabel: "Bank Name",
      type: "text",
      placeHolder: "Bank Name",
      required: true,
      name: "bank_name",
      isUpperCase: true,
      value: personalData?.bank_name,
      isReadOnly: true,
    },
    {
      id: 6,
      formLabel: "Bank Branch",
      type: "text",
      placeHolder: "Bank Branch",
      required: true,
      name: "bank_branch",
      isUpperCase: true,
      value: personalData?.bank_branch,
      isReadOnly: true,
    },
    {
      id: 7,
      formLabel: "Bank City",
      type: "text",
      placeHolder: "Bank City",
      required: true,
      name: "bank_city",
      value: personalData?.bank_city,
      isUpperCase: true,
      isReadOnly: true,
    },
    {
      id: 8,
      formLabel: "Upload Cancelled Cheque Proof",
      type: "file",
      placeHolder: "Upload Cancelled Cheque Proof",
      required: true,
      name: "upload_cancelled_cheque",
      error: errors.upload_cancelled_cheque,
      info: cancelledCheck ? (
        <i style={{ color: "green" }}>
          <span className="mdi mdi-checkbox-marked-circle "></span>
          File uploaded sucessfully
        </i>
      ) : (
        <i>
          <span className="mdi mdi-information-outline text-warning"></span>
          Supported file type png, jpg, pdf of max size: 2mb
        </i>
      ),
    },
    {
      id: 9,
      formLabel: "Agent Code (Not Mandatory)",
      type: "select",
      placeHolder: "Agent Code",
      required: false,
      name: "agent_code",
      option: agentsDetails,
      value: personalData?.agent_code,
    },
  ];
  const depositDetails = [
    {
      id: 1,
      formLabel: "Enter Deposit Amount",
      type: "text",
      placeHolder: "Enter Deposit Amount",
      required: true,
      name: "enter_deposit_amount",
      value: personalData?.enter_deposit_amount?.toLocaleString("en-IN"),
      //maxValue: trancheDetails?.balance_tranche_amount,
      minValue: 1000000,
    },
    {
      id: 2,
      formLabel: "Select Tranche",
      type: "select",
      placeHolder: "Select Tranche",
      required: true,
      name: "select_tranche",
      option: tranche,
      error: errors?.select_tranche,
    },
  ];

  const monthsToYears =
    (parseInt(personalData?.investment_period_id) === 1
      ? 24
      : parseInt(personalData?.investment_period_id) === 2
      ? 36
      : parseInt(personalData?.investment_period_id) === 3
      ? 48
      : 60) / 12;

  const selecteFrequency =
    parseInt(personalData?.interest_payment_id) === 1
      ? 12
      : parseInt(personalData?.interest_payment_id) === 2
      ? 3
      : 1;

  const months =
    parseInt(personalData?.investment_period_id) === 1
      ? 24
      : parseInt(personalData?.investment_period_id) === 2
      ? 36
      : parseInt(personalData?.investment_period_id) === 3
      ? 48
      : 60;

  const depositAmount = personalData?.enter_deposit_amount;
  const depositAmountInterest = depositAmount * monthsToYears * 0.12;
  const paymentRelease =
    parseInt(personalData?.interest_payment_id) === 1
      ? months / 1
      : parseInt(personalData?.interest_payment_id) === 2
      ? months / 3
      : months / 12;

  const finalPay = depositAmountInterest;
  const AmountToRelease = finalPay / paymentRelease;

  console.log("finalPay", finalPay);
  console.log("paymentRelease", paymentRelease);
  console.log("AmountToRelease", AmountToRelease);

  const onHandleChange = (e) => {
    console.log(e)
    const { name, value, files } = e.target;
    setPersonalData({ ...personalData, [name]: value });

    if (name === "select_tranche") {
      console.log("VALUE", value);
      tranche?.map((el) => {
        console.log(el);
        return parseInt(el.id) === parseInt(value)
          ? setTrancheDetails(el)
          : value == ""
          ? setTrancheDetails(undefined)
          : null;
      });
    }
    setPersonalData({ ...personalData, [name]: value });

    if (name === "upload_cancelled_cheque") {
      if (files[0].size <= 2097152) {
        if (
          files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf"
        ) {
          setErrors({ ...errors, upload_cancelled_cheque: null });

          setPersonalData({
            ...personalData,
            upload_cancelled_cheque: files[0],
          });
        } else {
          setErrors({ ...errors, upload_cancelled_cheque: "Unsupported File" });
          setPersonalData({ ...personalData, upload_cancelled_cheque: null });
        }
      } else {
        setErrors({
          ...errors,
          upload_cancelled_cheque: "File size should be less than 2MB",
        });
        setPersonalData({ ...personalData, upload_cancelled_cheque: null });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isExistingBankAccount) {
      dispatch(onNextPage("reviewPay"));
      onNext();
    } else {
      const localuser = JSON.parse(sessionStorage.getItem("user"));
      const localbank = JSON.parse(sessionStorage.getItem("bank"));

      console.log("Error", errors);

      const { confirm_account_number, ifsc_code, upload_cancelled_cheque } =
        errors;

      if (
        (confirm_account_number === "" || confirm_account_number === null) &&
        ifsc_code === null &&
        (upload_cancelled_cheque === "" || upload_cancelled_cheque === null)
      ) {
        let data = { ...personalData };

        let newInvestedDetails = {
          ...localInvestedData,
          deposit_amount: parseInt(personalData?.enter_deposit_amount),
          investment_period_id: parseInt(personalData?.investment_period_id),
          investment_period:
            parseInt(personalData?.investment_period_id) === 1
              ? 24
              : parseInt(personalData?.investment_period_id) === 2
              ? 36
              : parseInt(personalData?.investment_period_id) === 3
              ? 48
              : 60,
          interest_payment_id: parseInt(personalData?.interest_payment_id),
          interest_payment:
            parseInt(personalData?.interest_payment_id) === 1
              ? 12
              : parseInt(personalData?.interest_payment_id) === 2
              ? 3
              : 1,
          profit_amount: AmountToRelease,
          maturity_amount:
            parseInt(personalData?.enter_deposit_amount) +
            parseInt(personalData?.profit_amount),
          is_resident: parseInt(personalData?.is_resident),
          tranche: parseInt(personalData?.select_tranche),
        };

        console.log("new", newInvestedDetails);

        if (window.location.pathname === "/new-investment") {
          const formData = new FormData();
          formData.append("user_id", userDetails?.id ?? localuser?.id);
          formData.append("account_number", data?.account_number);
          formData.append(
            "account_holder_name",
            data?.account_holder_name?.toUpperCase()
          );
          formData.append("ifsc", data?.ifsc_code?.toUpperCase());
          formData.append("bank_name", data?.bank_name?.toUpperCase());
          formData.append("bank_branch", data?.bank_branch?.toUpperCase());
          formData.append("bank_city", data?.bank_city?.toUpperCase());
          formData.append("agent_id", data?.agent_code);
          formData.append(
            "cancelled_check_proof_file",
            data?.upload_cancelled_cheque
          );
          formData.append("status", 1);
          setTimeout(() => {
            setLoading(true);
            postMethod("api/user-bank-account", formData)
              .then((res) => {
                console.log(res);
                if (res?.data?.status === true) {
                  setLoading(false);
                  dispatch(onBankDetails(res?.data?.data));
                  sessionStorage.setItem(
                    "bank",
                    JSON.stringify(res?.data?.data)
                  );

                  if (window.location.pathname !== "/new-investment") {
                    sessionStorage.setItem(
                      "tranche",
                      JSON.stringify(trancheDetails)
                    );

                    sessionStorage.setItem(
                      "investmentDetails",
                      JSON.stringify(newInvestedDetails)
                    );
                  }
                  dispatch(onNextPage("reviewPay"));
                  onNext();
                }
              })
              .catch((err) => {
                setLoading(false);
                setErrors({
                  ...errors,
                  pan_card_number: err?.response?.data?.errors?.pan_card_number,
                  mobile_number: err?.response?.data?.errors?.mobile_number,
                  aadhaar_card_number:
                    err?.response?.data?.errors?.aadhaar_card_number,
                });
                console.log(err?.response?.data?.errors);
              });
          }, 500);
        } else {
          if (localbank) {
            const formData = new FormData();
            formData.append("user_id", userDetails?.id ?? localuser?.id);
            formData.append("account_number", data?.account_number);
            formData.append(
              "account_holder_name",
              data?.account_holder_name?.toUpperCase()
            );
            formData.append("ifsc", data?.ifsc_code?.toUpperCase());
            formData.append("bank_name", data?.bank_name?.toUpperCase());
            formData.append("bank_branch", data?.bank_branch?.toUpperCase());
            formData.append("bank_city", data?.bank_city?.toUpperCase());
            formData.append("agent_id", data?.agent_code);
            formData.append(
              "cancelled_check_proof_file",
              data?.upload_cancelled_cheque
            );
            formData.append("status", 1);
            formData.append("_method", "PUT");
            setTimeout(() => {
              setLoading(true);
              postMethod("api/user-bank-account/" + localbank?.id, formData)
                .then((res) => {
                  console.log(res);
                  if (res?.data?.status === true) {
                    setLoading(false);
                    dispatch(onBankDetails(res?.data?.data));
                    sessionStorage.setItem(
                      "bank",
                      JSON.stringify(res?.data?.data)
                    );
                    if (window.location.pathname !== "/new-investment") {
                      sessionStorage.setItem(
                        "tranche",
                        JSON.stringify(trancheDetails)
                      );
                    }
                    sessionStorage.setItem(
                      "investmentDetails",
                      JSON.stringify(newInvestedDetails)
                    );
                    dispatch(onNextPage("reviewPay"));
                    onNext();
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  setErrors({
                    ...errors,
                    pan_card_number:
                      err?.response?.data?.errors?.pan_card_number,
                    mobile_number: err?.response?.data?.errors?.mobile_number,
                    aadhaar_card_number:
                      err?.response?.data?.errors?.aadhaar_card_number,
                  });
                  console.log(err?.response?.data?.errors);
                });
            }, 500);
          } else {
            const formData = new FormData();
            formData.append("user_id", userDetails?.id ?? localuser?.id);
            formData.append("account_number", data?.account_number);
            formData.append("account_holder_name", data?.account_holder_name);
            formData.append("ifsc", data?.ifsc_code);
            formData.append("bank_name", data?.bank_name);
            formData.append("bank_branch", data?.bank_branch);
            formData.append("bank_city", data?.bank_city);
            formData.append("agent_id", data?.agent_code);
            formData.append(
              "cancelled_check_proof_file",
              data?.upload_cancelled_cheque
            );
            formData.append("status", 1);
            setTimeout(() => {
              setLoading(true);
              postMethod("api/user-bank-account", formData)
                .then((res) => {
                  console.log(res);
                  if (res?.data?.status === true) {
                    setLoading(false);
                    dispatch(onBankDetails(res?.data?.data));
                    sessionStorage.setItem(
                      "bank",
                      JSON.stringify(res?.data?.data)
                    );

                    if (window.location.pathname !== "/new-investment") {
                      sessionStorage.setItem(
                        "tranche",
                        JSON.stringify(trancheDetails)
                      );
                    }
                    sessionStorage.setItem(
                      "investmentDetails",
                      JSON.stringify(newInvestedDetails)
                    );
                    dispatch(onNextPage("reviewPay"));
                    onNext();
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  setErrors({
                    ...errors,
                    pan_card_number:
                      err?.response?.data?.errors?.pan_card_number,
                    mobile_number: err?.response?.data?.errors?.mobile_number,
                    aadhaar_card_number:
                      err?.response?.data?.errors?.aadhaar_card_number,
                  });
                  console.log(err?.response?.data?.errors);
                });
            }, 500);
          }
        }
      }
    }
  };

  const user = JSON.parse(sessionStorage.getItem("user"));
  const nominee = JSON.parse(sessionStorage.getItem("nominee"));
  const invested = JSON.parse(sessionStorage.getItem("investmentDetails"));
  const investment = JSON.parse(sessionStorage.getItem("investment"));
  const bank = JSON.parse(sessionStorage.getItem("bank"));

  const previousPage = () => {
    dispatch(onNextPage("nomineeDetails"));
    onPrevious("nomineeDetails");
  };

  const formRef = useRef([]);

  const getBankDetails = (ifscCode) =>
    getDetailsOfBank(ifscCode)
      .then((res) => {
        console.log("getBankDetails", res);
        setPersonalData({
          ...personalData,
          bank_name: res.data?.BANK,
          bank_branch: res?.data?.BRANCH,
          bank_city: res?.data.CITY,
        });
        setErrors({
          ...errors,
          ifsc_code: null,
        });
      })
      .catch((err) => {
        setPersonalData({
          ...personalData,
          bank_name: "",
          bank_branch: "",
          bank_city: "",
        });
        setErrors({
          ...errors,
          ifsc_code: "INVALID IFSC CODE",
        });
      });

  const accountNumberValidator = (e) => {
    const { name, value, files } = e.target;
    if (name === "confirm_account_number") {
      if (personalData.account_number !== personalData.confirm_account_number) {
        setErrors({
          ...errors,
          confirm_account_number: "Account Number Mismatch",
        });
        return false;
      }
      setErrors({
        ...errors,
        confirm_account_number: null,
      });
    }
    if (name === "ifsc_code") {
      getBankDetails(value);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const localuser = JSON.parse(sessionStorage.getItem("user"));

    if (window.location.pathname === "/new-investment") {
      getMethod(
        "api/user-bank-account/" + localuser?.id,
        controller.signal
      ).then((res) => {
        setLoading(false);
        setExistingBankAccount(res?.data?.data);
      });
    }
  }, []);

  const existingHandle = (e) => {
    let newData = existingBankAccount.find((data) => {
      if (data.id == e.target.value) return data;
    });
    console.log("newData", newData);
    const values = { ...newData };

    sessionStorage.setItem("bank", JSON.stringify(newData));
    setPersonalData({
      ...personalData,
      account_number: values.account_number,
      confirm_account_number: values.account_number,
      account_holder_name: values.account_holder_name,
      ifsc_code: values.ifsc,
      bank_name: values.bank_name,
      bank_branch: values.bank_branch,
      bank_city: values.bank_city,
      upload_cancelled_cheque: values.cancelled_check_proof,
      agent_code: values.agent_id,
      select_tranche: null,
      enter_deposit_amount: localInvestedData?.deposit_amount,
      is_resident: localInvestedData?.resident,
      interest_payment_id: 1,
      interest_payment: localInvestedData?.interest_payment,
      investment_period_id: 3,
      profit_amount: localInvestedData?.profit_amount,
    });
  };

  return (
    <Fade className="mncheckbox">
      <Container>
        <h6 className="text-warning">Bank Details</h6>
        <form onSubmit={onSubmit}>
          {window?.location?.pathname === "/new-investment" && (
            <div className="d-flex my-3">
              <Form.Group className="col-6">
                <Form.Check
                  label="Continue With Existing Bank Account"
                  inline
                  id="isExisting"
                  type="checkbox"
                  onClick={() =>
                    setIsExistingBankAccount(!isExistingBankAccount)
                  }
                />
              </Form.Group>
              {isExistingBankAccount && (
                <Form.Select
                  name="nominee_select"
                  onChange={existingHandle}
                  className="form-select rounded-0"
                >
                  <option value="">Select Bank Account</option>
                  {existingBankAccount?.map((data, index) => (
                    <option value={data.id}>
                      {data?.bank_name + "-" + data?.account_number}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>
          )}
          <Row>
            {bankDetails.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                <FormComponent
                  titleInfo={detail?.titleInfo}
                  key={index}
                  name={detail?.name}
                  info={detail?.info}
                  formRef={(el) => (formRef.current[index] = el)}
                  formLabel={detail?.formLabel}
                  type={detail?.type}
                  placeholder={detail?.placeHolder}
                  options={detail?.option}
                  required={detail?.required}
                  regex={detail?.regex}
                  isUpperCase={detail?.isUpperCase}
                  onHandleChange={onHandleChange}
                  validations={detail?.error}
                  onBlur={accountNumberValidator}
                  value={detail?.value}
                  defaultValue={detail?.value}
                  selected={detail?.value}
                  readonly={detail?.isReadOnly}
                  copy={detail?.isCopy}
                  paste={detail?.isPaste}
                  minValue={detail?.minValue}
                  visibleShow={detail?.visibleShow}
                  setVisibleShow={detail?.setVisibleShow}
                  disabled={isExistingBankAccount}
                />
              </Col>
            ))}
          </Row>

          {window.location.pathname !== "/new-investment" && (
            <>
              <h6 className="text-warning">Investment Details</h6>
              <Row>
                {depositDetails.map((detail, index) => (
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <FormComponent
                      titleInfo={detail?.titleInfo}
                      key={index}
                      name={detail?.name}
                      info={detail?.info}
                      formRef={(el) => (formRef.current[index] = el)}
                      formLabel={detail?.formLabel}
                      type={detail?.type}
                      placeholder={detail?.placeHolder}
                      options={detail?.option}
                      required={detail?.required}
                      regex={detail?.regex}
                      isUpperCase={detail?.isUpperCase}
                      onHandleChange={onHandleChange}
                      validations={detail?.error}
                      onBlur={accountNumberValidator}
                      value={detail?.value}
                      readonly={detail?.isReadOnly}
                      maxValue={detail?.maxValue}
                      minValue={detail?.minValue}
                    />
                  </Col>
                ))}
                {trancheDetails !== undefined && (
                  <>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="formCheckLabel">
                        <Form.Label className="d-block">
                          Resident Status <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Check
                          onChange={onHandleChange}
                          required
                          inline
                          label="Resident"
                          name="is_resident"
                          type="radio"
                          // defaultChecked={
                          //   localInvestedData?.resident == 1 ? true : false
                          // }
                          defaultChecked={Number(localInvestedData?.resident) === 1}
                          id="1"
                          value={1}
                        />
                        <Form.Check
                          onChange={onHandleChange}
                          required
                          inline
                          label="Non Resident"
                          name="is_resident"
                          type="radio"
                          id="2"
                          // defaultChecked={
                          //   localInvestedData?.resident == 2 ? true : false
                          // }
                          defaultChecked={Number(localInvestedData?.resident) === 2}
                          value={2}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={6}>
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          <div>
                            <Form.Label>Project Names </Form.Label>

                            {trancheDetails?.projects?.map((project) => (
                              <small className="d-flex flex-column grey fw-300">
                                {project?.project_name}{" "}
                              </small>
                            ))}
                          </div>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <div>
                            <Form.Label>Total Tranche Amount</Form.Label>
                            <h5 className="grey fw-300">
                              ₹ {priceFormat(trancheDetails?.investment_amount)}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          <div>
                            <Form.Label>Balance Tranche Amount </Form.Label>
                            <h5 className="grey fw-300">
                              ₹
                              {priceFormat(
                                parseInt(trancheDetails?.balance_tranche_amount)
                              )}
                            </h5>
                          </div>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <div>
                            <Form.Label>Investment End Date</Form.Label>
                            <h5 className="grey fw-300">
                              {moment(trancheDetails?.funding_end_date).format(
                                "DD-MM-YYYY"
                              )}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
                <hr />
                <Row className="align-items-center">
                  <Col xs={12} sm={12} lg={6}>
                    <Form.Label>
                      Select Investment period (in months)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mt-2 d-flex align-items-center ">
                      {investmentPeriodData.map((data, index) => (
                        <div className="inputGroup m-1">
                          <Form.Control
                            onChange={onHandleChange}
                            id={index + "" + data.investment_period}
                            name="investment_period_id"
                            type="radio"
                            defaultChecked={
                              personalData?.investment_period_id === data.id
                                ? true
                                : false
                            }
                            value={data.id}
                            onFocus={() => {
                              setErrors({
                                ...errors,
                                investment_period: null,
                              });
                            }}
                          />
                          <label htmlFor={index + "" + data.investment_period}>
                            {data.investment_period}
                          </label>
                        </div>
                      ))}
                    </div>
                    {errors?.investment_period && (
                      <span className="text-danger">
                        {errors?.investment_period}
                      </span>
                    )}
                  </Col>
                  <Col xs={12} sm={12} lg={6}>
                    <Row>
                      <Form.Label>
                        Interest Payment <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="row">
                        <Form.Group className="d-flex  formCheckLabel">
                          {interestPeriod?.map((profit, index) => (
                            <Form.Check
                              className="mx-2"
                              required
                              onChange={onHandleChange}
                              type="radio"
                              label={profit?.interest_profit_payment}
                              name="interest_payment_id"
                              value={profit?.id}
                              defaultChecked={true}
                              key={profit?.id}
                            />
                          ))}
                        </Form.Group>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <hr className="mt-2" />
              </Row>
            </>
          )}

          <div className="buttonGroup mb-3">
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="button"
              onClick={previousPage}
              variant="outline-warning rounded-0"
            >
              <span className="mdi mdi-chevron-left commonIcon"></span>
              Previous
            </Button>
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="submit"
              variant="warning rounded-0"
            >
              Next
              {loading ? (
                <Spinner
                  style={{
                    fontSize: "28px",
                    fontWeight: "100",
                    marginLeft: "5px",
                  }}
                  size="sm"
                  animation="border"
                  variant="light"
                />
              ) : (
                <span className="mdi mdi-chevron-right commonIcon"></span>
              )}
            </Button>
          </div>
        </form>
      </Container>
    </Fade>
  );
};

export default EBankInvestmentDetails;
